<template>
  <div>
    <lineFields
      :order="order"
      :valueArray="valueArray"
      :templateContent="templateContent"
      :fieldAttrRow="getfieldAttrRow()"
      :fields="localFields"
      :valueLine="valueLine"
      v-on="$listeners"
      @field-value-changed="checkInput(...arguments)"
      @field-value-changed-changed="checkChange(...arguments)"
      :checkReadOnly="
        typeof this.valueLine.readOnly != 'undefined' &&
        this.valueLine.readOnly === true
          ? true
          : false
      "
      :hideDetails="hideDetails"
    ></lineFields>
  </div>
</template>
<script>
import lineFields from "@/commonComponents/lineFields.vue";
export default {
  name: "lineFieldsContainer",
  components: { lineFields },
  data() {
    return {
      copiedFields: null,
    };
  },
  watch: {
    fields: {
      immediate: true,
      deep: true,
      handler(value) {
        this.copiedFields = this.deepCopy(value);
      }
    }
  },
  methods: {
    checkInput(field, value) {
      //console.log(index, field, value, text);
      if (!this.updateOnChange || this.fields[field].type != "text") {
        let localValueLine = { ...this.valueLine };
        // localLineFields[field] = value;
        this.$set(localValueLine, field, value);
        this.$emit("field-line-changed", localValueLine);
      }
    },
    checkChange(field, value) {
     // console.log("checkChange", field, value);
      if (this.updateOnChange && this.fields[field].type == "text") {
        let localValueLine = { ...this.valueLine };
        // localLineFields[field] = value;
        this.$set(localValueLine, field, value);
        this.$emit("field-line-changed", localValueLine);
      }
    },
    getfieldAttrRow() {
      let fieldAttrRow = {};
      if (typeof this.fieldAttrRow == "function") {
        fieldAttrRow = this.fieldAttrRow(this.valueLine);
      } else {
        fieldAttrRow = this.fieldAttrRow;
      }
      return fieldAttrRow;
    },
  },
  computed: {
    localFields() {
      //console.log(index, info);
      // stringfy distroy functions
      // let localField = JSON.parse(JSON.stringify(this.fields));

      let localField = this.copiedFields;
      //let localField = { ...this.fields };
      //console.log(localField);
      // localField.index.value = index;
      // localField.value.value = info;
      let caller = this;
      Object.keys(this.fields).forEach(function (field) {
        caller.$set(localField[field], "value", caller.valueLine[field]);
        if (typeof localField[field]["fieldAttrInput"] == "undefined") {
          caller.$set(localField[field], "fieldAttrInput", {});
        }
        caller.$set(
          localField[field]["fieldAttrInput"],
          "id",
          caller.linePrefix + "_" + caller.order + "_" + field
        );

        if (caller.headerTitle === true) {
          // localField[field].name = "";
          caller.$set(localField[field], "name", "");
        }

        for (const [key, value] of Object.entries(localField[field])) {
          if (
            typeof value == "function" &&
            key != "options" &&
            key != "asyncOptions"
          ) {
            //localField[field][key] = value(caller.valueLine);
            caller.$set(localField[field], key, value(caller.valueLine));
          }
        }
        //  console.log(Object.entries(caller.rerunsArray).length);

        if (
          Object.entries(caller.rerunsArray).length !== 0 &&
          caller.rerunsArray.includes(field)
        ) {
          caller.$set(localField[field], "rerun", true);
          // localField[field].rerun = true;
        } else {
          caller.$set(localField[field], "rerun", false);
          //localField[field].rerun = false;
        }
        if (caller.headerTitle) {
          caller.$set(localField[field], "innerLabel", false);
        }
      });
      return localField;
    },
  },

  props: {
    hideDetails: { type: Boolean, default: false },
    order: { type: Number, default: 0 },
    valueArray: {},
    templateContent: {},
    headerTitle: Boolean,
    fieldAttrRow: {},
    valueLine: {},
    fields: { Type: Object },
    rerunsArray: {
      Type: Object,
      default() {
        return {};
      },
    },
    updateOnChange: {
      Type: Object,
      default: Boolean,
    },

    linePrefix: { type: String, default: "" },
  },
};
</script>