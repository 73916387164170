<template>
  <v-row no-gutters>
    <v-col>
      <primaryTextfield
        v-if="result.report.editable==true"
        v-model="localValue"
        :fieldAttrInput="fieldAttrInput"
        label="Date Format"
      ></primaryTextfield>
      <div v-else class="mt-2">
        <label type="text" class="timeFormatText momentFormat ">{{localValue}}</label>
      </div>
    </v-col>
    <v-col class="pa-2 mt-1">{{time}}</v-col>
  </v-row>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import moment from "moment";
export default {
  props: ["result", "value", "fieldAttrInput"],
  components: { primaryTextfield },
  data() {
    return { time: "" };
  },
  created() {
    let caller = this;
    setInterval(function () {
      caller.updateTime();
    }, 1000);
  },
  methods: {
    updateTime() {
      this.time = moment().format(this.localValue);
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
 